import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks';
import Synaps from '@synaps-io/react-verify';
import { WHITELISTS } from 'config';
import axios from 'axios';

import './index.scss';

const Mint = () => {
  const { address } = useGetAccountInfo();
  const isLoggedIn = Boolean(address);

  const [data, setData] = useState({
    account: '',
    sandbox: false,
    session_id: '',
    status: 'disconnect'
  });
  const [isWhitelist, setIsWhitelist] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        if (isLoggedIn && WHITELISTS.includes(address)) {
          const res = await axios.get(
            `https://s.cyberpunkcity.com/create_session?account=${address}`
          );
          console.log(res.status);
          if (res.status == 200) {
            const value = {
              account: res.data.account,
              sandbox: res.data.sandbox,
              session_id: res.data.session_id,
              status: res.data.status
            };
            console.log(value);
            setData(value);
          }
          setIsWhitelist(true);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [address]);

  return (
    <div className='container my-4'>
      <h1 className='text-center'>KYC — Know Your Customer</h1>
        <p className='text-center blockquote mb-0'>Cyberpunk City is Determined to Continue on its Path and Decided to Do KYC to Ensure its Steps are Sound</p>
        <p className='text-center blockquote-footer'>Therefore, we highly encourage people who have participated in the CYBER presale (in any phase) to do the KYC.</p>

          <Row className='d-flex justify-content-center border border-danger mx-1 my-3 p-2'>
              <p className='text-center display-4 mb-0'>KYC SUBMISSIONS FINISHED!</p>
              <p className='text-center m-0'><small>Thank you for participating. Giveaway results: <a href="https://twitter.com/cyberpunkcity_/status/1629114260700688386" target="_blank">https://twitter.com/cyberpunkcity_/status/1629114260700688386</a></small></p>
          </Row>
      <h5>ESSENTIAL</h5>
      <p>Cyberpunk City is taking the proper steps to stay on track, and this step is one of them. Moreover, completing the KYC as soon as possible is essential to help the future listings (DEX/CEX).</p>
      <h5>SECURE</h5>
      <p>We make sure that your data is safe and secure and will only be used for the intended purposes.</p>
      <h5>EASY</h5>
      <p>We partnered with <a href='https://synaps.io/' target='_blank' className='link-info'>Synaps</a> (also partner of MultiversX) to make the process more convenient and straightforward. Moreover, the team will answer your questions regarding the KYC process and, if needed, talk with you in person.</p>
      <h5>GIVEAWAY 🎉</h5>
      <p>We will make a giveaway of a total of 10 EGLD to 10 lucky people chosen randomly between the KYC attendees.</p>

      <br /><small><b>RESIDENTS OF THE FOLLOWING COUNTRIES ARE EXCLUDED FROM PARTICIPATING KYC:</b> Afghanistan, Albania, Barbados, Belarus, Burkina Faso, Burundi, Cambodia, Cameroon, Cayman Islands, Central African Republic , Chad, Chile, Colombia, Congo (the Democratic Republic of the), Egypt, Guinea, Guinea-Bissau, Haiti, Iran, Iraq, Jamaica, Jordan, Kenya, Korea (the Democratic People's Republic of), Lebanon, Libya, Mali, Morocco, Mozambique, Myanmar, Nicaragua, Niger, Nigeria, Pakistan, Panama, Philippines, Russian Federation, Senegal, Somalia, South Sudan, Sudan, Syrian Arab Republic, Trinidad and Tobago, Tunisia, Uganda, United Arab Emirates, Vanuatu, Venezuela, Yemen, Zimbabwe.</small>
    </div>
  );
};

export default Mint;
