import { RouteType } from '@elrondnetwork/dapp-core/types';
import { dAppName } from 'config';
import { withPageTitle } from './components/PageTitle';

import Mint from './pages/Mint';

export const routeNames = {
  mint: '/',
  unlock: '/unlock'
};

interface RouteWithTitleType extends RouteType {
  title: string;
}

export const routes: RouteWithTitleType[] = [
  {
    path: routeNames.mint,
    title: 'Mint',
    component: Mint
  },
];

export const mappedRoutes = routes.map((route) => {
  const title =
    'Cyberpunk City (CYBER) KYC Verification | Secure, essential, easy, giveaway.';

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth
  };
});
