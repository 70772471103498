import React, { useEffect, useState } from 'react';
import { useGetIsLoggedIn } from '@elrondnetwork/dapp-core/hooks';
import { useGetAccountInfo } from '@elrondnetwork/dapp-core/hooks';
import { logout } from '@elrondnetwork/dapp-core/utils';
import { Navbar as BsNavbar, NavItem, Nav, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { dAppName, GATEWAY } from 'config';
import { routeNames } from 'routes';
import { ReactComponent as CPCLogo } from 'assets/img/cyberpunk-city-logo.svg';
import { ReactComponent as Lightning } from 'assets/img/lightning.svg';
import axios from 'axios';

import './index.scss';

export const Navbar = () => {
  const navigate = useNavigate();
  const { address } = useGetAccountInfo();

  const handleLogout = () => {
    logout(`${window.location.origin}/unlock`);
  };
  const handleLogin = () => {
    navigate(routeNames.unlock, { replace: true });
  };

  const isLoggedIn = Boolean(address);

  return (
    <BsNavbar
      collapseOnSelect
      className='navbar-cpc'
      expand='md'
      variant='light'
    >
      <Container className='custom-navbar-container' fluid>
        <BsNavbar.Brand>
          <Link
            className='d-flex align-items-center navbar-brand mr-0'
            to={routeNames.mint}
          >
            <CPCLogo className='cpc-logo' />
          </Link>
        </BsNavbar.Brand>
        <BsNavbar.Toggle aria-controls='responsive-navbar-nav' />
        <BsNavbar.Collapse id='responsive-navbar-nav' className='nav-menu-wrap'>
          <Nav id='responsive-navbar-nav' className='ml-auto'>
            {/* <Link
              className='navbar-links'
              to={routeNames.mint}
              aria-current='page'
            >
              Mint
            </Link> */}
          </Nav>
          {isLoggedIn ? (
            <>
              <button
                className='is-style-cpc-btn-styled connect'
                onClick={handleLogout}
              >
                <Lightning className='mr-1' />
                DISCONNECT
              </button>
            </>
          ) : (
            <button
              className='is-style-cpc-btn-styled connect'
              onClick={handleLogin}
            >
              <Lightning className='mr-1' />
              CONNECT
            </button>
          )}
        </BsNavbar.Collapse>
      </Container>
    </BsNavbar>
  );
};
